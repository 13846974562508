import React from "react"
import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"

import { hex2rgba } from "../../styles/tools"
import Row from "../../styles/Row"
// create a new pulse animation for the strong
const pulse = keyframes`
  0% {
    opacity: 0;
    transform: rotate(17deg) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: rotate(-5deg) scale(1);
  }
`

const EnergyLineBannerWrapper = styled.div`
  position: relative;
  z-index: 200;

  /* background-image: linear-gradient(45deg, #533279 0%, #3986be 100%); */
  /* background-image: linear-gradient(45deg, #f93699 0%, #d71431 100%); */
  background-color: #111111;
  color: #fafafa;

  transition: background 0.2s ease-in-out;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(90deg, #f93699 0%, #d71431 100%);
  }
`
EnergyLineBannerWrapper.row = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 1rem 0.325rem;
  text-align: center;

  font-family: ${props => props.theme.font.heading};
  font-size: 1.25rem;

  media (max-width: 640px) {
    font-size: 1rem;
  }

  media (max-width: 480px) {
    font-size: 0.9rem;
  }

  strong {
    display: inline-block;
    margin-right: 0.625em;

    opacity: 0;

    animation: ${pulse} 0.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-delay: 0.6s;
  }

  span {
    color: #f93699;
  }
`

export default function EnergyLineBanner(props) {
  return (
    <EnergyLineBannerWrapper>
      <EnergyLineBannerWrapper.row>
        <div>
          <strong>NEW!</strong> <span>Energy Freeze</span> line coming soon!
        </div>
      </EnergyLineBannerWrapper.row>
    </EnergyLineBannerWrapper>
  )
}
