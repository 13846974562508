import React, { useState, useRef, useEffect, useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"

import { getResults } from "../../api/finder"

import { milkshakeStyle, smoothieStyle } from "./mapStyle"
import LoadingMask from "./LoadingMask"
import PermissionMask from "./PermissionMask"
import LocationInput from "./LocationInput"
import ResultsList from "./ResultsList"
import { frealThemeContext } from "../../data/ThemeContext"

const Wrapper = styled.div`
  position: relative;

  padding: 1rem;

  #freal-finder-map {
    border-radius: 0.75rem;
  }
`

const MapWrapper = styled.div`
  position: relative;

  height: 0;
  transition: height 0.3s ease-in-out;

  overflow: hidden;

  &[data-active="true"] {
    height: 400px;
  }

  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: block;
    width: 4rem;
    height: 4rem;
    /* content: ""; */
    pointer-events: none;

    border-radius: 4rem;
    border: 3px solid red;
  }
  &[data-dragging="true"] {
    border-color: blue;
  }
`

const UpdateButton = styled.button`
  position: absolute;
  z-index: 999;
  bottom: 1rem;
  left: 1rem;

  display: block;
  margin: 0.75rem;
  padding: 1rem 1.5rem 0.7rem;

  border: none;
  border-radius: 20rem;
  background-color: ${props => props.theme.color.primary};

  font-family: ${props => props.theme.font.heading};
  font-size: 1.8em;
  color: ${props => props.theme.color.whiteBg};

  transition: all 0.2s ease-in-out;
`

const FilterButton = styled.button`
  position: absolute;
  z-index: 999;
  bottom: 5rem;
  left: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.75rem;
  padding: 0.6rem 1.5rem 0.5rem 1.25rem;

  border: none;
  border-radius: 20rem;
  background-color: ${props => props.theme.color.whiteBg};

  font-family: ${props => props.theme.font.heading};
  font-size: 1.8em;
  color: ${props => props.theme.color.primary};
  text-align: left;
  cursor: pointer;
  line-height: 1rem;

  box-shadow: 0px 3px 8px rgba(31, 77, 121, 0.2);

  transition: all 0.2s ease-in-out;

  svg {
    display: block;
    margin: 0 0.5rem 0 0;
    width: 36px;

    path {
      fill: ${props => props.theme.color.primary};

      &.filter-checkmark-mask {
        fill: ${props => props.theme.color.secondary};
      }
    }

    .filter-checkmark,
    .filter-checkmark-mask {
      display: none;
    }
  }
  &[data-active="true"] {
    background-color: ${props => props.theme.color.secondary};

    .filter-checkmark,
    .filter-checkmark-mask {
      display: block;
    }
  }

  sup {
    font-size: 0.6em;
    line-height: 1rem;
  }

  small {
    display: block;
    margin: 0 0 0.25rem;
    font-size: 0.6em;
  }
`

const options = {
  styles: milkshakeStyle,
  mapTypeControl: false,
}
const libraries = ["places"]

let googleMaps = null
const ltoSearch = false

export default function Finder(props) {
  const {
    isModal,
    finderSearchTerm,
    finderSearchLat,
    finderSearchLng,
    storeFilter,
  } = props

  // if (typeof document !== "undefined") {
  //   queryParams = new URL(document.location).searchParams
  //   ltoSearch = queryParams.get("ltoSearch") === "true"
  // }

  const themeContext = useContext(frealThemeContext)
  const themeDataContext = useContext(ThemeContext)

  if (themeContext.data.themeName === "milkshakes") {
    options.styles = milkshakeStyle
  } else if (themeContext.data.themeName === "smoothies") {
    options.styles = smoothieStyle
  } else {
    options.styles = milkshakeStyle
  }

  const [showMap, setShowMap] = useState(!isModal)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDV5yvan953q_mlvPFtWuvZTSG38YSBFf4",
    libraries: libraries,
    preventGoogleFontsLoading: true,
  })

  const [initialSearch, setInitialSearch] = useState(false)
  const [loadingResults, setLoadingResults] = useState(false)
  const [results, setResults] = useState([])

  let map = useRef(null)
  const [geocoder, setGeocoder] = useState(null)
  // const [boundsClass, setBounds] = useState(null)
  const loadedMap = React.useCallback(function onLoad(mapInstance) {
    // console.log("Loaded the map")
    map.current = mapInstance

    const newGeocoder = new window.google.maps.Geocoder()
    setGeocoder(newGeocoder)

    googleMaps = window.google.maps
  }, [])

  // If opened via modal input
  useEffect(() => {
    if (isModal && finderSearchLat && finderSearchLng) {
      updatePlace(finderSearchLat, finderSearchLng)
    }
  }, [finderSearchLat, finderSearchLng]) // eslint-disable-line react-hooks/exhaustive-deps

  // If on finder page: get the user's current position
  const [askNavPermission, setAskNavPermission] = useState(!isModal)
  // const [giveNavPermission, setGiveNavPermission] = useState(false)
  const [navLatitude, setNavLatitude] = useState(null)
  const [navLongitude, setNavLongitude] = useState(null)
  // const { latitude, longitude, error } = usePosition()

  // GEO things
  const onNavGeoChange = async position => {
    setNavLatitude(position.coords.latitude)
    setNavLongitude(position.coords.longitude)
    // setGiveNavPermission(true)
    setLoadingResults(true)
  }
  const getNavPermission = allow => {
    if (allow) {
      const geo = navigator.geolocation
      if (!geo) {
        return
      }

      setLoadingResults(true)
      geo.getCurrentPosition(onNavGeoChange, error => {
        setAskNavPermission(false)
        // setGiveNavPermission(false)
        setLoadingResults(false)
        console.warn({ error })
      })
    } else {
      // setGiveNavPermission(false)
      setLoadingResults(false)
    }

    setAskNavPermission(false)
  }
  useEffect(() => {
    if (!isModal && navLatitude && navLongitude) {
      updatePlace(navLatitude, navLongitude)
    }
  }, [navLatitude, navLongitude]) // eslint-disable-line react-hooks/exhaustive-deps

  // Map things
  const defaultCenterLat = 39.829
  const defaultCenterLng = -98.579
  const [centerLat, setCenterLat] = useState(defaultCenterLat)
  const [centerLng, setCenterLng] = useState(defaultCenterLng)

  const defaultZoomLevel = 4
  const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel)

  const moveMapOnUpdate = useRef(true)

  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const [showFilterButton, setShowFilterButton] = useState(false)

  const [enableLtoFilter, setEnableLtoFilter] = useState(false)

  const updatePlace = async (lat, lng, didClick = false) => {
    // moveMapOnUpdate.current = !didClick

    if (!showRefreshButton) {
      setShowRefreshButton(true)
    }

    if (!showFilterButton && ltoSearch === true) {
      setShowFilterButton(true)
    }

    // console.log("Updated place", { lat }, { lng })
    // setCenterLat(lat)
    // setCenterLng(lng)
    // setZoomLevel(9)

    setAskNavPermission(false)
    // setGiveNavPermission(false)

    if (!loadingResults || !initialSearch) {
      setInitialSearch(true)
      setLoadingResults(true)
      setResults([])

      const locationResults = await getResults(
        lat,
        lng,
        storeFilter,
        enableLtoFilter
      ).catch(err => {
        console.log(err)
      })
      const locations =
        locationResults && locationResults.length > 0 ? locationResults : []
      setResults(locations)
      setInitialSearch(false)
      setLoadingResults(false)
    }
  }

  useEffect(() => {
    if (results.length > 0 && map.current && googleMaps) {
      const newBounds = new googleMaps.LatLngBounds()

      const newResults = [...results]

      newResults.forEach(result => {
        newBounds.extend({
          lat: parseFloat(result.latitude),
          lng: parseFloat(result.longitude),
        })
      })

      if (moveMapOnUpdate.current === true) {
        const firstResult = newResults[0]
        setCenterLat(parseFloat(firstResult.latitude))
        setCenterLng(parseFloat(firstResult.longitude))
        setZoomLevel(13)

        // const newCenter = newBounds.getCenter()
        // setCenterLat(newCenter.lat())
        // setCenterLng(newCenter.lng())

        // map.current.fitBounds(newBounds) // Just the zoom level
      }
    }
  }, [results])

  const geocodeLocation = location => {
    if (geocoder !== null) {
      geocoder.geocode({ address: location }, function(results, status) {
        if (status === "OK") {
          // console.log("GEOCODE SUCCESS!", results)
          const lat = results[0].geometry.location.lat()
          const lng = results[0].geometry.location.lng()
          updatePlace(lat, lng)
        }
      })
    } else {
      console.warn("NO GEOCODER!")
    }
  }

  const refreshLocationsAction = (moveMap = false) => {
    moveMapOnUpdate.current = moveMap

    if (map.current) {
      const newCenter = map.current.getCenter()
      const newLat = newCenter.lat()
      const newLng = newCenter.lng()
      updatePlace(newLat, newLng, true)
    }
  }

  const dragTracker = useRef(null)
  const setDragTracker = () => {
    dragTracker.current = setTimeout(() => {
      if (map.current) {
        const newCenter = map.current.getCenter()
        const newLat = newCenter.lat()
        const newLng = newCenter.lng()
        setCenterLat(newLat)
        setCenterLng(newLng)
        // updatePlace(newLat, newLng)
      }
    }, 500)
  }

  useEffect(() => {
    if (!askNavPermission) {
      refreshLocationsAction(true)
    }
  }, [enableLtoFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clearTimeout(dragTracker.current)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderMap = () => {
    let markerZindex = results.length > 0 ? results.length + 1 : 26
    return (
      <Wrapper>
        <MapWrapper data-active={showMap}>
          {loadingResults && <LoadingMask />}
          {askNavPermission && (
            <PermissionMask
              getNavPermission={getNavPermission}
              setShowMap={setShowMap}
            />
          )}

          {((ltoSearch && showFilterButton) ||
            (ltoSearch && !askNavPermission)) &&
            !loadingResults && (
              <FilterButton
                onClick={e => {
                  e.preventDefault()
                  setEnableLtoFilter(!enableLtoFilter)
                }}
                data-active={enableLtoFilter}
              >
                <div>
                  <svg
                    viewBox="0 0 43 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27 3H9C5.68629 3 3 5.68629 3 9V27C3 30.3137 5.68629 33 9 33H27C30.3137 33 33 30.3137 33 27V9C33 5.68629 30.3137 3 27 3ZM9 0C4.02944 0 0 4.02944 0 9V27C0 31.9706 4.02944 36 9 36H27C31.9706 36 36 31.9706 36 27V9C36 4.02944 31.9706 0 27 0H9Z"
                      fill="#1F4D79"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9 0C4.02944 0 0 4.02944 0 9V27C0 31.9706 4.02944 36 9 36H27C31.9706 36 36 31.9706 36 27V9C36 4.02944 31.9706 0 27 0H9ZM27 3H9C5.68629 3 3 5.68629 3 9V27C3 30.3137 5.68629 33 9 33H27C30.3137 33 33 30.3137 33 27V9C33 5.68629 30.3137 3 27 3Z"
                      fill="#1F4D79"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.269 13.5233L19.2401 19.4885L35.3397 3.56451C36.1007 2.81186 37.2715 2.81186 37.9743 3.56451L40.0818 5.59122C40.7843 6.34387 40.7843 7.50188 40.0818 8.1971L20.5873 27.4789C19.8264 28.1737 18.6556 28.1737 17.9527 27.4789L8.52689 18.1559C7.82439 17.4611 7.82439 16.3031 8.52689 15.55L10.6344 13.5233C11.3369 12.7707 12.5077 12.7707 13.269 13.5233ZM9.21584 12.1128C10.7425 10.5281 13.1818 10.6247 14.6751 12.101L14.6825 12.1084L19.2471 16.6685L33.9333 2.14256C35.4268 0.665282 37.8661 0.569853 39.3927 2.15377L41.5071 4.18708L41.5439 4.22656C42.9338 5.71562 43.0119 8.11117 41.4887 9.61866L21.9654 28.9289L21.9359 28.9558C20.443 30.319 18.0581 30.3954 16.5467 28.9012L7.12045 19.5779C5.59626 18.0703 5.67548 15.6746 7.06447 14.1857L7.10148 14.1461L9.21584 12.1128Z"
                      className="filter-checkmark-mask"
                    />
                    <path
                      d="M19.2401 19.4884L35.3397 3.56449C36.1006 2.81184 37.2714 2.81184 37.9743 3.56449L40.0818 5.5912C40.7843 6.34385 40.7843 7.50186 40.0818 8.19708L20.5873 27.4789C19.8264 28.1737 18.6556 28.1737 17.9527 27.4789L8.52687 18.1559C7.82438 17.4611 7.82438 16.303 8.52687 15.55L10.6344 13.5233C11.3369 12.7707 12.5077 12.7707 13.269 13.5233L19.2401 19.4884Z"
                      className="filter-checkmark"
                    />
                  </svg>
                </div>
                <div>
                  <small>Find:</small>
                  <div>S'mores</div>
                </div>
              </FilterButton>
            )}

          {(showRefreshButton || !askNavPermission) && !loadingResults && (
            <UpdateButton
              onClick={e => {
                e.preventDefault()
                refreshLocationsAction(true)
              }}
            >
              Refresh Locations
            </UpdateButton>
          )}

          <GoogleMap
            id="freal-finder-map"
            options={options}
            onLoad={loadedMap}
            mapContainerStyle={{
              height: "400px",
              width: "100%",
            }}
            zoom={zoomLevel}
            center={{
              lat: centerLat,
              lng: centerLng,
            }}
            onDragStart={e => {
              clearTimeout(dragTracker.current)
            }}
            onDragEnd={e => {
              setDragTracker()
            }}
          >
            {results.length > 0 &&
              results.map((result, resultIndex) => {
                markerZindex -= 1

                return (
                  <Marker
                    key={resultIndex}
                    title={result.store_locator_name}
                    position={{
                      lat: parseFloat(result.latitude),
                      lng: parseFloat(result.longitude),
                    }}
                    optimized={false}
                    icon={{
                      anchor: {
                        x: 15,
                        y: 40,
                      },
                      // url: "/assets/images/marker.svg",
                      scaledSize: {
                        height: 40,
                        width: 30,
                      },
                      labelOrigin: {
                        x: 16,
                        y: 17,
                      },
                      path:
                        "m15 0c2.7083 0 5.2083 0.67708 7.5 2.0312 2.2917 1.3542 4.1146 3.1771 5.4688 5.4688s2.0312 4.7917 2.0312 7.5c0 1.6667-0.20833 3.138-0.625 4.4141-0.38462 1.1779-1.1021 2.6109-2.1524 4.2992l-0.26951 0.42738c-0.56667 0.88542-1.5428 2.3368-2.9283 4.3541l-2.228 3.224-5.2344 7.5c-0.36458 0.52083-0.88542 0.78125-1.5625 0.78125s-1.1979-0.26042-1.5625-0.78125l-1.4844-2.1528c-1.0764-1.5509-2.3264-3.3333-3.75-5.3472l-2.4682-3.5741c-1.18-1.7212-2.0412-3.0019-2.5838-3.8419l-0.10425-0.16212c-1.1979-1.875-2.0052-3.4505-2.4219-4.7266-0.41667-1.276-0.625-2.7474-0.625-4.4141 0-2.7083 0.67708-5.2083 2.0312-7.5 1.3542-2.2917 3.1771-4.1146 5.4688-5.4688 2.2917-1.3542 4.7917-2.0312 7.5-2.0312z",
                      strokeWeight: 0,
                      strokeOpacity: 0,
                      fillColor:
                        resultIndex === 0
                          ? themeDataContext.color.heroButtonBg1
                          : themeDataContext.color.primary,
                      fillOpacity: 1,
                      scale: 1,
                    }}
                    zIndex={markerZindex}
                    label={{
                      text: (resultIndex + 1).toString(),
                      color: "#FFF",
                      fontSize: "16px",
                      fontFamily: '"VeneerCleanRound", sans-serif',
                      fontWeight: "bold",
                    }}
                  >
                    Marker
                  </Marker>
                )
              })}
          </GoogleMap>
        </MapWrapper>

        <LocationInput
          updatePlace={updatePlace}
          geocodeLocation={geocodeLocation}
          finderSearchTerm={finderSearchTerm}
          showMap={showMap}
          setShowMap={setShowMap}
        />

        {results.length > 0 && <ResultsList results={results} />}
      </Wrapper>
    )
  }

  if (loadError) {
    return <div>Error loading map!</div>
  }

  return isLoaded ? renderMap() : <div></div>
}
