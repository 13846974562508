import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { hex2rgba } from "../../styles/tools"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
`

const NavLink = styled(Link)`
  display: block;
  padding: 0.5rem 1.5rem;

  color: ${props => props.theme.color.lightBg};
  font-family: ${props => props.theme.font.heading};
  font-size: 2.4em;

  &[aria-current="page"] {
    color: ${props => props.theme.color.secondary};
  }

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
`

const ToggleNav = styled.nav`
  position: absolute;
  left: 50%;
  bottom: 14vh;
  transform: translateX(-50%);

  display: flex;
  padding: 0.5rem 0;

  a {
    display: block;
    padding: 0.2rem 0.5rem;
    color: ${props => props.theme.color.navigationBg};
    transition: color 0.2s ease-in-out;

    font-family: ${props => props.theme.font.heading};
    font-size: 1.2em;
  }

  [data-button="toggle"] {
    position: relative;

    display: block;
    margin: 0 0.5rem;
    padding: 0.25rem;

    span {
      position: absolute;
      left: 0;
      top: 0;

      display: block;
      width: 0px;
      height: 0px;
      overflow: hidden;
      font-size: 0;
      color: transparent;
      user-select: none;
    }

    &::before,
    &::after {
      position: relative;
      display: block;
      content: "";
      transition: all 0.2s ease-in-out;
    }
    &::before {
      width: 3.75rem;
      height: 100%;

      border-radius: 2rem;
      background-color: ${props => props.theme.color.navigationBg};
      box-shadow: 0 1px 3px
        ${props => {
          return hex2rgba(props.theme.color.primaryBackground, 0.5)
        }}
        inset;
    }
    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 2rem;
      height: 2rem;

      border-radius: 2rem;
      box-shadow: 0 2px 4px
        ${props => {
          return hex2rgba(props.theme.color.primaryBackground, 0.6)
        }};
      background-color: ${props => props.theme.color.highlight};
    }
  }
`

export default function TouchModal(props) {
  const { setActive } = props

  return (
    <Content>
      <Nav>
        <NavLink
          to="/flavors"
          onClick={e => {
            setActive(false)
          }}
        >
          The Flavors
        </NavLink>
        <NavLink
          to="/how-it-works"
          onClick={e => {
            setActive(false)
          }}
        >
          How It Works
        </NavLink>
        <NavLink
          to="/our-story"
          onClick={e => {
            setActive(false)
          }}
        >
          Our Story
        </NavLink>
        <NavLink
          to="/finder"
          onClick={e => {
            setActive(false)
          }}
        >
          Find a f'real
        </NavLink>
      </Nav>

      <ToggleNav>
        <a data-button="business" href="/business">
          Business
        </a>
        <a data-button="toggle" href="/business">
          <span>Go to Business Site</span>
        </a>
        <Link data-button="consumer" to="/">
          Consumer
        </Link>
      </ToggleNav>
    </Content>
  )
}
