import React from "react"
import styled, { keyframes } from "styled-components"

import { hex2rgba } from "../../styles/tools"

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem 2rem;
  width: 100%;
  height: 400px;

  background-color: ${props => hex2rgba(props.theme.color.lightBg, 0.8)};

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;

    display: block;
    width: 100%;
    height: 4rem;

    content: "";

    background-image: linear-gradient(
      to bottom,
      ${props => hex2rgba(props.theme.color.lightBg, 0.5)} 0%,
      ${props => props.theme.color.lightBg} 100%
    );
  }
`

const dotFadeIn1 = keyframes`
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`
const dotFadeIn2 = keyframes`
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`
const dotFadeIn3 = keyframes`
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;

  div {
    font-family: ${props => props.theme.font.heading};
    font-size: 3em;
    line-height: 1em;
    color: ${props => props.theme.color.primary};
    text-align: center;
    text-shadow: 1px 2px 3px 1px rgba(255, 255, 255, 0.8);

    @media (max-width: 640px) {
      font-size: 2em;
    }
  }

  span {
    position: relative;

    opacity: 1;

    animation-name: ${dotFadeIn1};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      content: ".";
      opacity: 1;

      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
    &::before {
      left: 0.5rem;
      animation-name: ${dotFadeIn2};
    }
    &::after {
      left: 1rem;
      animation-name: ${dotFadeIn3};
    }
  }
`

export default function LoadingMask(props) {
  return (
    <Wrapper>
      <Title>
        <div>
          Looking for shakes and smoothies<span>.</span>
        </div>
      </Title>
    </Wrapper>
  )
}
