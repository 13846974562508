import React from "react"
import styled from "styled-components"

import Touch from "./Touch"
import EnergyLineBanner from "./EnergyLineBanner"
import Banner from "./Banner"
import Navigation from "./Navigation"

const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
`

export default function Header(props) {
  return (
    <HeaderWrapper>
      <EnergyLineBanner />
      <Touch />
      <Banner />
      <Navigation />
    </HeaderWrapper>
  )
}
