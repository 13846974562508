import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"

// Style imports
import { GlobalStyles } from "../styles/GlobalStyles"

import milkshakes from "../styles/themes/milkshakes"
import smoothies from "../styles/themes/smoothies"
// import protein from "../styles/themes/protein"

import ThemeContextConsumer, {
  ThemeContextProviderComponent,
} from "../data/ThemeContext"

import { StateProvider, stateReducer, initialState } from "../data/StateContext"

import Header from "../components/Header/"
import Footer from "../components/Footer"
import TouchFinder from "../components/TouchFinder"
// import PopupModal from "../components/PopupModal"
import LayoutWrapper from "../styles/LayoutWrapper"
import "./layout.css" // This is a bit of a reset

const Layout = props => {
  const { children, location } = props

  const queryData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let theme = milkshakes

  return (
    <ThemeContextProviderComponent>
      <ThemeContextConsumer>
        {({ data }) => {
          if (data.themeName === "milkshakes") {
            theme = milkshakes
          } else if (data.themeName === "smoothies") {
            theme = smoothies
          }

          return (
            <ThemeProvider theme={theme}>
              <StateProvider initialState={initialState} reducer={stateReducer}>
                <GlobalStyles />
                <Header siteTitle={queryData.site.siteMetadata.title} />
                <LayoutWrapper>{children}</LayoutWrapper>

                <Footer location={location} />

                {/* <PopupModal /> */}

                {!location.pathname.startsWith("/finder") && <TouchFinder />}
              </StateProvider>
            </ThemeProvider>
          )
        }}
      </ThemeContextConsumer>
    </ThemeContextProviderComponent>
  )
}

Layout.propTypes = {
  theme: PropTypes.any,
  themeName: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
