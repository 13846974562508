const base = {
  font: {
    body: `"Dagny", sans-serif`,
    heading: `"VeneerCleanRound", sans-serif`,
  },

  color: {
    primary: "#004E7D",
    primaryBackground: "#004E7D",
    secondary: "#FAEEBD",
    highlight: "#57C1E9",

    whiteBg: "#DAF6FF",
    lightBg: "#CBF1FF",
    mediumBg: "#BFE8FD",
    darkBg: "#A4D2EB",

    bannerBg: "#004E7D",
    navigationBg: "#D3F8FF",
    logoBg: "#007BB8",

    heroButtonBg1: "#007BB8",
    heroButtonBg2: "#57C1E9",
    heroButtonBg3: "#A4D2EB",
  },
}

export default base
