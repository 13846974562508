// https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

import React, { createContext, useContext, useReducer } from "react"

export const initialState = {
  finderLoadingResults: false,
  finderResults: [],
  finderModalVisible: false,
  finderSearchTerm: "",
  finderSearchLat: 0,
  finderSearchLng: 0,
}

export const StateContext = createContext(initialState)

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)

export const stateReducer = (state, action) => {
  switch (action.type) {
    case "updateFinderLoadingResults":
      return {
        ...state,
        finderLoadingResults: action.finderLoadingResults,
      }

    case "getFinderResults":
      return {
        ...state,
        finderLoadingResults: true,
        finderModalVisible: true,
        finderSearchTerm: action.finderSearchTerm,
        finderSearchLat: action.finderSearchLat,
        finderSearchLng: action.finderSearchLng,
      }

    case "updateFinderResults":
      return {
        ...state,
        finderLoadingResults: false,
        finderResults: action.finderResults,
      }

    case "clearFinderResults":
      return {
        ...initialState,
      }

    case "closeFinderModal":
      return {
        ...state,
        finderLoadingResults: false,
        finderModalVisible: false,
      }

    default:
      return state
  }
}
