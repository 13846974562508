import base from "./base"

const themeColors = {
  primary: "#2E7739",
  primaryBackground: "#2E7739",
  secondary: "#FAEEBD",
  highlight: "#5ED86F",

  whiteBg: "#DEF7E3",
  lightBg: "#D0EBD5",
  mediumBg: "#C0E8C7",
  darkBg: "#87CB91",

  bannerBg: "#2E7739",
  navigationBg: "#DEF7E3",
  logoBg: "#2E7739",

  heroButtonBg1: "#47AF56",
  heroButtonBg2: "#5ED86F",
  heroButtonBg3: "#8CF59B",
}

const smoothiesColor = Object.assign(base.color, themeColors)
const smoothies = { ...base, color: { ...smoothiesColor } }

export default smoothies
