import { createGlobalStyle } from "styled-components"

import { hex2rgba } from "../styles/tools"

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;

    outline-color: ${props => props.theme.color.highlight};
  }

  body {
    font-family: ${props => props.theme.font.body};
    font-size: 16px;
    letter-spacing: 1px;

    background-color: ${props => props.theme.color.lightBg};
    transition: background-color 0.2s ease-in-out;

    -webkit-tap-highlight-color: ${props =>
      hex2rgba(props.theme.color.mediumBg, 0.4)};
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.color.primary};
    transition: all 0.2s ease-in-out;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.color.primary};
    transition: color 0.2s ease-in-out;

    font-family: ${props => props.theme.font.heading};
  }

  .pac-container {
    z-index: 10000;

    &:last-of-type {
      margin-top: -220px;
    }
  }
`
