import React, { useState, useRef } from "react"
import { Autocomplete, useLoadScript } from "@react-google-maps/api"
import styled from "styled-components"

import { useStateValue } from "../../data/StateContext"
import { hex2rgba } from "../../styles/tools"

const Title = styled.h2`
  display: block;
  margin: 0 0 1rem;

  color: #fff;
`

const Form = styled.form`
  position: relative;
  display: inline-block;
  max-width: 100%;

  input[type="text"],
  button {
    display: block;

    background-color: transparent;

    font-size: 1.2em;
  }

  input[type="text"] {
    padding: 0.675rem 3rem 0.675rem 1.5rem;

    color: ${props => props.theme.color.lightBg};
    font-weight: bold;

    border: 3px solid ${props => props.theme.color.lightBg};
    border-radius: 200px;
    outline: none;
    max-width: 100%;

    transition: all 0.2s ease-in-out;

    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 6px
        ${props => hex2rgba(props.theme.color.highlight, 0.5)};
    }

    &::placeholder {
      font-style: italic;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 0;

    padding: 0.675rem 1rem;
    height: 100%;

    cursor: pointer;
    border: none;

    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.color.secondary};

    &:hover {
      color: #fff;
    }
  }
`

let geocoder = null
const libraries = ["places"]

export default function Finder(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDV5yvan953q_mlvPFtWuvZTSG38YSBFf4",
    libraries: libraries,
    preventGoogleFontsLoading: true,
  })

  const [{ finderLoadingResults }, dispatch] = useStateValue()

  const submitForm = e => {
    e.preventDefault()
    if (!finderLoadingResults) {
      onPlaceChanged()
    }
  }

  const [autocomplete, setAutocomplete] = useState(null)
  const onAutocompleteLoad = a => {
    setAutocomplete(a)
  }

  let inputRef = useRef(null)

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace()
      if (inputRef) {
        inputRef.blur()
      }
      if (place && place.geometry) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        updatePlace(lat, lng)
        // console.log("Found a place", lat, lng)
      } else {
        const backupValue = inputRef && inputRef.value ? inputRef.value : ""
        const searchLocation = place && place.name ? place.name : backupValue
        // console.log("Geocode a place", searchLocation)
        geocodeLocation(searchLocation)
      }
    }
  }

  const geocodeLocation = location => {
    if (geocoder !== null) {
      geocoder.geocode({ address: location }, function(results, status) {
        if (status === "OK") {
          // console.log("GEOCODE SUCCESS!", results)
          const lat = results[0].geometry.location.lat()
          const lng = results[0].geometry.location.lng()
          updatePlace(lat, lng)
          // console.log("Geocoded!", lat, lng)
        }
      })
    } else {
      console.warn("NO GEOCODER!")
    }
  }

  const updatePlace = (latitude, longitude) => {
    dispatch({
      type: "getFinderResults",
      finderLoadingResults: true,
      finderSearchTerm: inputRef.value,
      finderSearchLat: latitude,
      finderSearchLng: longitude,
    })
  }

  const renderInput = () => {
    const newGeocoder = new window.google.maps.Geocoder()
    geocoder = newGeocoder

    return (
      <div>
        <Title>Find your Local f'real</Title>

        <Form onSubmit={e => e.preventDefault()}>
          <Autocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              placeholder="Enter your zip code..."
              ref={el => {
                inputRef = el
              }}
            />
          </Autocomplete>
          <button onClick={submitForm} type="button">
            Go!
          </button>
        </Form>
      </div>
    )
  }

  return isLoaded ? renderInput() : <div></div>
}
