import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { hex2rgba } from "../../styles/tools"
import Row from "../../styles/Row"

const BannerWrapper = styled.div`
  background-color: ${props => props.theme.color.bannerBg};
  transition: background 0.2s ease-in-out;

  @media (max-width: 900px) {
    display: none;
  }
`
BannerWrapper.row = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.125rem 0;
  height: 40px;

  a {
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;

    text-transform: uppercase;
    font-family: ${props => props.theme.font.heading};
    font-size: 1rem;
  }
`

const SocialNav = styled.nav`
  display: flex;

  a {
    color: ${props => props.theme.color.secondary};
    transition: color 0.2s ease-in-out;
  }
`

const ToggleNav = styled.nav`
  display: flex;

  a {
    color: ${props => props.theme.color.navigationBg};
    transition: color 0.2s ease-in-out;
  }

  [data-button="toggle"] {
    position: relative;

    width: 50px;
    padding: 0.25rem;

    span {
      position: absolute;
      left: 0;
      top: 0;

      display: block;
      width: 0px;
      height: 0px;
      overflow: hidden;
      font-size: 0;
      color: transparent;
      user-select: none;
    }

    &::before,
    &::after {
      position: relative;
      display: block;
      content: "";
      transition: all 0.2s ease-in-out;
    }
    &::before {
      width: 44px;
      height: 12px;

      border-radius: 2rem;
      background-color: ${props => props.theme.color.navigationBg};
      box-shadow: 0 1px 3px
        ${props => {
          return hex2rgba(props.theme.color.primaryBackground, 0.5)
        }}
        inset;
    }
    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 20px;
      height: 20px;

      border-radius: 2rem;
      box-shadow: 0 2px 4px
        ${props => {
          return hex2rgba(props.theme.color.primaryBackground, 0.6)
        }};
      background-color: ${props => props.theme.color.highlight};
    }
  }
`

export default function Banner(props) {
  return (
    <BannerWrapper>
      <BannerWrapper.row>
        <SocialNav>
          <a
            href="https://www.instagram.com/therealfreal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://www.tiktok.com/@therealfreal"
            target="_blank"
            rel="noopener noreferrer"
          >
            TikTok
          </a>
          <a
            href="https://www.youtube.com/@therealfreal"
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
        </SocialNav>

        <ToggleNav>
          <a data-button="business" href="/business">
            Business
          </a>
          <a data-button="toggle" href="/business">
            <span>Go to Business Site</span>
          </a>
          <Link data-button="consumer" to="/">
            Consumer
          </Link>
        </ToggleNav>
      </BannerWrapper.row>
    </BannerWrapper>
  )
}
