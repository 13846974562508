import "./src/assets/fonts/fonts.css"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
    console.log("👍 intersection-observer imported")
  }

  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    require(`object-fit-images`)()
    console.log(`👍 Object-fit/Object-position are polyfilled`)
  }
}

// var mixpanel = require("mixpanel-browser")
// window.mixpanel = mixpanel
// mixpanel.init("4a94b14042bc2ca5dd5dba78f9f9e9b8")
