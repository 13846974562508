import styled from "styled-components"

export const Container = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;
`

export const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.675);
  cursor: pointer;
`

export const CloseButton = styled.button`
  position: absolute;
  z-index: 2;
  right: 0.75rem;
  top: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;

  overflow: hidden;
  background-color: transparent;
  border: none;

  color: ${props => props.theme.color.primary};
  font-size: 2rem;
  line-height: 1rem;
  cursor: pointer;
`

export const Content = styled.div`
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  padding: 2rem;
  width: 70vw;
  height: 70vh;
  max-width: calc(100% - 4rem);
  max-height: calc(100% - 4rem);

  border-radius: 1.875rem;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.color.navigationBg};

  overflow: auto;

  @media (max-width: 820px) {
    padding: 1.5rem;
    width: 85vw;
    height: 85vh;
  }

  @media (max-width: 560px) {
    padding: 0.5rem;
    width: 96vw;
    height: 96vh;
  }
`
