import React from "react"
import ReactDOM from "react-dom"

import Finder from "../Finder"

import { useStateValue } from "../../data/StateContext"

import { Container, Overlay, CloseButton, Content } from "../../styles/Modal"

export default function FinderModal(props) {
  const [
    { finderSearchTerm, finderSearchLat, finderSearchLng },
    dispatch,
  ] = useStateValue()

  const closeModal = e => {
    dispatch({
      type: "closeFinderModal",
    })
  }

  return ReactDOM.createPortal(
    <Container>
      <Overlay onClick={closeModal} />
      <Content>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <Finder
          isModal={true}
          finderSearchTerm={finderSearchTerm}
          finderSearchLat={finderSearchLat}
          finderSearchLng={finderSearchLng}
        />
      </Content>
    </Container>,
    document.body
  )
}
