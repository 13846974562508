import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import FooterLogo from "../../images/footer-logo.svg"

const Wrapper = styled.div`
  margin-top: 4rem;
  padding-bottom: 2rem;

  a:hover {
    text-decoration: underline;
  }
`

const LogoWrapper = styled.div`
  margin-bottom: 0.5rem;

  path {
    transition: all 0.2s ease-in-out;
  }

  .light-bg {
    /* fill: ${props => props.theme.color.lightBg}; */
    fill: #fff;
  }

  .logo-bg {
    fill: ${props => props.theme.color.primaryBackground};
  }
`

export default function Colophon(props) {
  return (
    <Wrapper>
      <LogoWrapper>
        <FooterLogo />
      </LogoWrapper>
      <p>
        &copy; {new Date().getFullYear()} &bull; f'real foods LLC &bull;{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>
      </p>
    </Wrapper>
  )
}
