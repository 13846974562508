import axios from "axios"

export const getResults = async (
  latitude,
  longitude,
  storeFilter,
  enableLtoFilter = false
) => {
  // console.log("API: Getting the results:", { latitude }, { longitude })

  // const testApiEndpoint =
  //   "https://freal-finder.gecko/wp-json/finder/v1/locations"

  // "https://frealapi.wpengine.com/wp-json/finder/v1/locations"
  const apiEndpoint =
    "https://frealapi.wpengine.com/wp-json/finder/v1/locations"

  let response = await axios.get(apiEndpoint, {
    params: {
      latitude,
      longitude,
      storeFilter: encodeURIComponent(storeFilter),
      enableLtoFilter: enableLtoFilter ? true : false,
    },
  })

  let returnData = []
  if (response.data && response.data.location_count) {
    const data = { ...response.data }

    if (data.locations && data.locations.length) {
      data.locations.sort((a, b) => {
        const aDist = parseFloat(a.distance)
        const bDist = parseFloat(b.distance)
        if (aDist > bDist) {
          return 1
        } else if (aDist < bDist) {
          return -1
        } else {
          return 0
        }
      })

      returnData = data.locations.slice(0, 25)
    }
  }

  return returnData
}
