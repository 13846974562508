import React, { useState, useRef, useEffect } from "react"
import { Autocomplete } from "@react-google-maps/api"
import styled from "styled-components"

import { hex2rgba } from "../../styles/tools"

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 1.5rem 1rem;
  }

  h1 {
    display: block;
    margin: 0 2rem 0 0;

    @media (max-width: 1024px) {
      margin: 0 0 1rem;
    }
  }
`

const Form = styled.form`
  position: relative;
  display: block;
  margin: 0;

  input[type="text"],
  button {
    display: block;

    font-size: 1.2em;
  }

  input[type="text"] {
    padding: 0.5rem 6rem 0.5rem 1rem;
    width: 360px;
    max-width: 100%;

    color: ${props => props.theme.color.primary};
    font-weight: bold;

    border: 3px solid ${props => props.theme.color.lightBg};
    border-radius: 200px;
    outline: none;
    box-shadow: 0 0 0 3px ${props => props.theme.color.lightBg},
      0 0 0 6px ${props => props.theme.color.primaryBackground};

    background-color: ${props => props.theme.color.lightBg};
    -webkit-appearance: none;

    transition: all 0.2s ease-in-out;

    @media (max-width: 600px) {
      width: 100%;
    }

    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 3px ${props => props.theme.color.lightBg},
        0 0 0 6px ${props => props.theme.color.primaryBackground},
        0 0 0 12px ${props => hex2rgba(props.theme.color.highlight, 0.75)};
    }

    &::placeholder {
      font-style: italic;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 0;

    padding: 0.5rem 1.25rem 0.5rem 0.75rem;
    height: 100%;

    cursor: pointer;
    border: none;
    border-radius: 0 200px 200px 0;
    background-color: ${props => props.theme.color.primaryBackground};
    transition: all 0.2s ease-in-out;

    box-shadow: -9px 0 0 0 ${props => props.theme.color.heroButtonBg1},
      -18px 0 0 0 ${props => props.theme.color.heroButtonBg2},
      -27px 0 0 0 ${props => props.theme.color.heroButtonBg3};

    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.color.secondary};

    &:hover {
      color: #fff;
    }
  }
`

const ShowMapButton = styled.button`
  position: relative;

  display: block;
  margin: 1rem 1.5rem;
  width: 6rem;

  border: none;
  background-color: transparent;

  font-weight: bold;
  font-size: 0.9em;
  color: ${props => props.theme.color.primary};
`

export default function LocationInput(props) {
  const {
    updatePlace,
    geocodeLocation,
    finderSearchTerm,
    showMap,
    setShowMap,
  } = props

  const submitForm = e => {
    e.preventDefault()
    onPlaceChanged()
  }

  const [autocomplete, setAutocomplete] = useState(null)
  const onAutocompleteLoad = a => {
    setAutocomplete(a)
  }

  let inputRef = useRef(null)

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace()
      if (inputRef) {
        inputRef.blur()
      }
      if (place && place.geometry) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        updatePlace(lat, lng, false)
      } else {
        const backupValue = inputRef && inputRef.value ? inputRef.value : ""
        const searchLocation = place && place.name ? place.name : backupValue
        geocodeLocation(searchLocation)
      }
    }
  }

  useEffect(() => {
    if (finderSearchTerm && inputRef) {
      inputRef.value = finderSearchTerm
    }
  }, [finderSearchTerm])

  return (
    <Wrapper>
      <h1>Find a f'real near you:</h1>
      <Form onSubmit={e => e.preventDefault()}>
        <Autocomplete
          onLoad={onAutocompleteLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Enter your zip code..."
            onFocus={e => {
              e.target.value = ""
            }}
            ref={el => {
              inputRef = el
            }}
          />
        </Autocomplete>
        <button onClick={submitForm} type="button">
          Go!
        </button>
      </Form>

      <div>
        <ShowMapButton
          data-map-active={showMap}
          onClick={e => {
            setShowMap(!showMap)
          }}
        >
          {showMap ? "Hide Map" : "Show Map"}
        </ShowMapButton>
      </div>
    </Wrapper>
  )
}
