import styled from "styled-components"

const LayoutWrapper = styled.div`
  padding: 100px 0 0;

  @media (max-width: 900px) {
    padding: 76px 0 0;
  }
`

export default LayoutWrapper
